<template>
  <div>
    <v-container>
      <page-title title="CPAR Category">
        <template slot="action">
          <v-btn class="mx-1" color="primary" @click="redirect('Setting.CPARCategory.Add')">Add</v-btn>
        </template>
      </page-title>

      <v-row>
        <v-col>
          <list-data ref="refListData"></list-data>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ListData from "./components/ListData.vue";

export default {
  components: { ListData, },

  methods: {
    addCallback(){
      this.$refs.refListData.refreshData();
    }
  },

  mounted() {
    this.modulePermission("cparcategory", "view", true);
  }
};
</script>
